import Router from 'next/router';
import getNextConfig from 'next/config';

export const routeNoAuth = (path) => {
    const route = [
        '/',
        '/_error',
        '/401',
        '/500',
        '/authentication',
        '/dashboard/login',
        '/account/login',
        '/account/dashboard',
        '/login',
        '/track-order',
        '/track-order/[state]',
        '/place_order/payment/[orderId]/unfinish',
        '/place_order/payment/[orderId]/failed',
        '/success',
        '/confirmpayment',
        '/thank-you/[orderId]',
        '/subscription',
        '/thankyou',
        '/subscription',
    ];

    const found = route.find((val) => val === path);

    return typeof found === 'undefined';
};

const redirect = (res, route) => {
    if (typeof window !== 'undefined') {
        Router.push(route);
    } else {
        res.redirect(route);
    }
};

const routeMiddleware = (params) => {
    const {
        res, pathname, isLogin,
    } = params;
    const login = typeof isLogin === 'string' ? parseInt(isLogin, 0) : isLogin;
    const { publicRuntimeConfig } = getNextConfig();
    const { mode } = publicRuntimeConfig;

    const sharedRoutes = [
        '/_error',
        '/401',
        '/confirmpayment',
        '/track-order/[state]',

    ];

    const dashboardRoutes = [
        '/dashboard',
        '/dashboard/login',
        '/dashboard/order',
        '/dashboard/order/[id]',
        '/dashboard/account',
        '/dashboard/ewallet',
        '/account/dashboard',
        '/account/login',
        '/account/address',
        '/account/orders',
        '/account/orders/view/order_id/[id]',
        '/account/affiliate',
        '/account/swift-loyalty',
    ];

    const checkoutRoutes = [
        '/',
        '/authentication',
        '/login',
        '/account/login',
        '/account/dashboard',
        '/account/address',
        '/account/orders',
        '/account/affiliate',
        '/account/swift-loyalty',
        '/place_order/payment/[orderId]/unfinish',
        '/place_order/payment/[orderId]/failed',
        '/success',
        '/thank-you/[orderId]',
        '/subscription',
        '/thankyou',
        '/subscription',
        '/dashboard/order',
        '/dashboard/order/[id]',
        '/account/orders/view/order_id/[id]',
    ];
    const isSharedRoute = sharedRoutes.find((route) => route === pathname);
    const isDashboardRoute = dashboardRoutes.find((route) => route === pathname);
    const isCheckoutRoute = checkoutRoutes.find((route) => route === pathname);

    if (!login) {
        const needLogin = routeNoAuth(pathname);

        if (needLogin) {
            if (mode === '' || mode === 'dashboard') {
                redirect(res, '/account/login');
            }

            if (mode === 'checkout') {
                redirect(res, '/login');
            }
        } else {
            if (mode === 'dashboard' && !isDashboardRoute && !isSharedRoute) {
                redirect(res, '/account/address');
            }

            if (mode === 'checkout' && !isCheckoutRoute && !isSharedRoute) {
                redirect(res, '/');
            }
        }
    } else {
        if (mode === 'dashboard' && !isDashboardRoute && !isSharedRoute) {
            redirect(res, '/account/address');
        }

        if (mode === 'checkout' && !isCheckoutRoute && !isSharedRoute) {
            redirect(res, '/');
        }
    }
};

export default routeMiddleware;
